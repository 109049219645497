import * as React from "react";
import PropTypes from "prop-types";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import AgencyInfoCard from "../../../components/AgencyInfoCard/AgencyInfoCard";
import Testimonial from "../../../components/Testimonial/Testimonial";
import RocketReferralsCta from "../../../components/RocketReferralsCta/RocketReferralsCta";

import {
  agencyInfoColumnOne,
  agencyInfoColumnTwo,
  agentProfileContainer,
  agentProfilePicture,
  agentProfileInfo,
  agencyInfoContainer,
  agencyInfoFirstRow,
  agencyHeadingTitle,
  agencyInfoSecondRow,
  agencyInfoThirdRow,
  cardContainer,
  cardShadow,
  cardContentContainer,
} from "./AgencyStyleSmall.module.scss";
import {
  sky,
  cloud,
  mountainRight,
  mountainLeft,
} from "../../../styles/design.module.scss";

export default function AgencyStyleSmall({
  agencyID,
  agencyName,
  agencyType,
  logo,
  profilePhoto,
  website,
  email,
  nps,
  aboutUs,
  testimonialID,
}) {
  return (
    <>
      <section className="wrapper">
        <div className={agencyInfoContainer}>
          <div className={agencyInfoColumnOne}>
            <div className={agentProfileContainer}>
              {profilePhoto && (
                <GatsbyImage
                  className={agentProfilePicture}
                  image={profilePhoto}
                  alt={`${agencyName} profile photo`}
                />
              )}
              <div className={agentProfileInfo}>
                <a href={`${website}`}>{website}</a>
                <a href={`mailto:${email}`}>{email}</a>
              </div>
            </div>
          </div>
          <div className={agencyInfoColumnTwo}>
            <div className={agencyInfoFirstRow}>
              <div className={cardContainer}>
                <div className={cardShadow}>
                  <div className={cardContentContainer}>
                    {logo && (
                      <GatsbyImage
                        image={logo}
                        style={{ maxWidth: "10rem" }}
                        alt={`${agencyName} logo`}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className={agencyHeadingTitle}>
                <h1>{agencyName}</h1>
              </div>
            </div>
            <hr
              style={{
                margin: "3rem 0",
                borderTop: "1px solid rgba(5,67,114,.1)",
              }}
            />
            <div className={agencyInfoSecondRow}>
              <AgencyInfoCard nps={nps} />
              <AgencyInfoCard overall={true} agencyID={agencyID} />
              <AgencyInfoCard agencyID={agencyID} agencyType={agencyType} />
            </div>
            <div className={agencyInfoThirdRow}>
              <h2>About {agencyName}</h2>
              <p>{aboutUs}</p>
            </div>
          </div>
        </div>
      </section>
      <div className="background-white">
        <section className="wrapper">
          <Testimonial testimonialID={testimonialID} />
        </section>
      </div>

      <div style={{ position: "relative" }}>
        <div className={sky}>
          <div
            className="wrapper"
            style={{ position: "relative", zIndex: "1500", height: "100%" }}
          >
            <StaticImage
              className={mountainLeft}
              objectFit="contain"
              placeholder="none"
              quality="100"
              src="../../../images/mountain_left.svg"
              alt=""
            />
            <StaticImage
              className={mountainRight}
              objectFit="contain"
              placeholder="none"
              quality="100"
              src="../../../images/mountain_right.svg"
              alt=""
            />
          </div>

          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
        </div>
        <RocketReferralsCta
          h2="We can make NPS work for you."
          buttonText="Check out Rocket Referrals"
          link="https://www.rocketreferrals.com/features"
        />
      </div>
    </>
  );
}

AgencyStyleSmall.defaultProps = {
  email: ``,
  name: ``,
  about: ``,
  website: ``,
  type: ``,
  nps: 0,
};

AgencyStyleSmall.propTypes = {
  email: PropTypes.string,
  name: PropTypes.string.isRequired,
  about: PropTypes.string,
  website: PropTypes.string,
  type: PropTypes.string,
  nps: PropTypes.number.isRequired,
};
