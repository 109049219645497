import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { capitalizeFirstLetter } from "../../utilities/capitalize";
import { cardColor } from "../../utilities/colors";
import {
  cardContainer,
  cardContentContainer,
  cardShadow,
} from "./AgencyInfoCard.module.scss";

/**
 * Returns the info card with content fed from Strapi on the individual Agency pages.
 *
 * */

export default function AgencyInfoCard({ agencyID, nps, agencyType, overall }) {
  const data = useStaticQuery(graphql`
    query rankings {
      overall: allStrapiAgencies(sort: { fields: info___nps, order: DESC }) {
        nodes {
          id
          Agency
          slug
          info {
            about
            email
            id
            name
            nps
            website
            type
          }
        }
      }
      personal: allStrapiAgencies(
        sort: { fields: info___nps, order: DESC }
        filter: { info: { type: { eq: "personal" } } }
      ) {
        nodes {
          id
          Agency
          slug
          info {
            about
            email
            id
            name
            nps
            website
            type
          }
        }
      }
      commercial: allStrapiAgencies(
        sort: { fields: info___nps, order: DESC }
        filter: { info: { type: { eq: "commercial" } } }
      ) {
        nodes {
          id
          Agency
          slug
          info {
            about
            email
            id
            name
            nps
            website
            type
          }
        }
      }
      new: allStrapiAgencies(
        sort: { fields: info___nps, order: DESC }
        filter: { info: { type: { eq: "new" } } }
      ) {
        nodes {
          id
          Agency
          slug
          info {
            about
            email
            id
            name
            nps
            website
            type
          }
        }
      }
    }
  `);

  /* We're running 4 aliased queries that are the same, but with different filters.
   * We're using the power of graphQL to sort the agencies for us and then using the index to determine placement.
   * In the actual mapping we're adding 1 to the resulting index since we're zero-based.
   * May need to look into a different solution, but this gives us our intended results.
   */

  let overallRank;
  let typeRank;

  if (overall) {
    overallRank = data.overall.nodes
      .map((agency) => agency.id)
      .indexOf(agencyID);
  }
  if (agencyType) {
    if (agencyType === "personal") {
      typeRank = data.personal.nodes
        .map((agency) => agency.id)
        .indexOf(agencyID);
    }
    if (agencyType === "commercial") {
      typeRank = data.commercial.nodes
        .map((agency) => agency.id)
        .indexOf(agencyID);
    }
    if (agencyType === "new") {
      typeRank = data.new.nodes.map((agency) => agency.id).indexOf(agencyID);
    }
  }

  return (
    <div className={cardContainer}>
      <div className={cardShadow}>
        <div
          className={cardContentContainer}
          style={
            agencyType
              ? { backgroundColor: cardColor(agencyType), color: "#fff" }
              : null
          }
        >
          {nps && (
            <div>
              <span>Net Promoter Score</span>
              <h1>{parseFloat(nps.toFixed(2))}</h1>
            </div>
          )}
          {overall && (
            <div>
              <span>Overall Rank</span>
              <h1>#{overallRank + 1}</h1>
            </div>
          )}
          {agencyType && (
            <div>
              <span>{capitalizeFirstLetter(agencyType)} Rank</span>
              <h1>#{typeRank + 1}</h1>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
