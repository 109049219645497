import * as React from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import AgencyLayout from "../../Layouts/AgencyLayout/AgencyLayout";
import Layout from "../../Layouts/Layout/Layout";
import Seo from "../../components/Seo/Seo";
import AgencyStyleSmall from "../../templates/AgencyPages/AgencyStyleSmall/AgencyStyleSmall";
import AgencyStyleLarge from "../../templates/AgencyPages/AgencyStyleLarge/AgencyStyleLarge";
import IndiumAgency from "../../templates/AgencyPages/IndiumAgency/IndiumAgency";

/**
 *
 *  AgencyLayout (src/templates/AgencyLayout/) => Provides additional styling ontop of the default Layout.
 *  AgencyInfo (src/components/AgencyInfo/) => Container for content section containing the AgencyInfo cards, pictures, logos etc.
 */

export default function Agency(props) {
  const { id, template, testimonial_ID } = props.data.strapiAgencies;
  const { about, email, name, nps, type, website, logo, profile } =
    props.data.strapiAgencies.info;

  const logoImageObj = getImage(logo?.localFile);
  const profileImageObj = getImage(profile?.localFile);

  const enterprise = props.data.strapiAgencies.enterprise;

  if (template === "small") {
    return (
      <AgencyLayout>
        <Seo title={name} />
        <AgencyStyleSmall
          agencyID={id}
          agencyName={name}
          agencyType={type}
          logo={logoImageObj}
          profilePhoto={profileImageObj}
          website={website}
          email={email}
          nps={nps}
          aboutUs={about}
          testimonialID={testimonial_ID}
        />
      </AgencyLayout>
    );
  }
  if (template === "large") {
    return (
      <AgencyLayout>
        <Seo title={name} />
        <AgencyStyleLarge
          agencyID={id}
          agencyName={name}
          agencyType={type}
          logo={logoImageObj}
          profilePhoto={profileImageObj}
          website={website}
          email={email}
          nps={nps}
          aboutUs={about}
          testimonialID={testimonial_ID}
        />
      </AgencyLayout>
    );
  }

  if (template === "indium") {
    return (
      <Layout homepageLink="/indium">
        <Seo title={name} />
        <IndiumAgency
          agencyName={name}
          logo={logoImageObj}
          profilePhoto={profileImageObj}
          website={website}
          email={email}
          nps={nps}
          aboutUs={about}
          testimonialID={testimonial_ID}
          enterprise={enterprise}
        />
      </Layout>
    );
  }
}

//page query is ran and passes the specific page's info to the above component via props

export const query = graphql`
  query agencyData($id: String!) {
    strapiAgencies(id: { eq: $id }) {
      Agency
      enterprise {
        Name
      }
      id
      slug
      info {
        about
        email
        id
        name
        nps
        type
        website
        logo {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        profile {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      testimonial_ID
      template
    }
  }
`;
