import * as React from "react";
import PropTypes from "prop-types";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import AgencyInfoCard from "../../../components/AgencyInfoCard/AgencyInfoCard";
import Testimonial from "../../../components/Testimonial/Testimonial";
import RocketReferralsCta from "../../../components/RocketReferralsCta/RocketReferralsCta";

import {
  agencyInfoColumnOne,
  agencyInfoColumnTwo,
  logoImage,
  logoTitle,
  agentProfileContainer,
  agentProfilePicture,
  agentProfileInfo,
  agencyInfoContainer,
  agencyInfoFirstRow,
  agencyHeadingTitle,
  agencyInfoSecondRow,
  agencyInfoThirdRow,
  indiumBannerImage,
  indiumImageContainer,
  indiumLogo,
} from "./IndiumAgency.module.scss";
import {
  sky,
  cloud,
  mountainRight,
  mountainLeft,
} from "../../../styles/design.module.scss";

export default function IndiumAgency({
  agencyName,
  logo,
  profilePhoto,
  website,
  email,
  nps,
  aboutUs,
  testimonialID,
}) {
  return (
    <>
      <section style={{ position: "relative" }}>
        <div className={indiumBannerImage}></div>

        <section className="wrapper" style={{ paddingTop: "3rem" }}>
          <div className={indiumImageContainer}>
            <StaticImage
              src="../../../images/indium_logo.png"
              alt="Indium logo"
              placeholder="none"
              className={indiumLogo}
            />
          </div>
          <div className={agencyInfoContainer}>
            <div className={agencyInfoColumnOne}>
              <div className={agentProfileContainer}>
                <div className={logoTitle}>
                  {logoImage && (
                    <GatsbyImage
                      className={logoImage}
                      image={logo}
                      alt={`${agencyName} logo`}
                    />
                  )}
                  <h1 className={agencyHeadingTitle}>{agencyName}</h1>
                </div>
                <hr />
                <div className={agentProfileInfo}>
                  <a href={`${website}`}>{website}</a>
                  <a href={`mailto:${email}`}>{email}</a>
                </div>
              </div>
            </div>
            <div className={agencyInfoColumnTwo}>
              <div className={agencyInfoFirstRow}>
                {profilePhoto && (
                  <GatsbyImage
                    image={profilePhoto}
                    className={agentProfilePicture}
                    alt={`${agencyName} profile photo`}
                  />
                )}
              </div>
              <div className={agencyInfoSecondRow}>
                <AgencyInfoCard nps={nps} />
              </div>
              <div className={agencyInfoThirdRow}>
                <h2>About {agencyName}</h2>
                <p>{aboutUs}</p>
              </div>
            </div>
          </div>
        </section>
      </section>
      <div className="background-white">
        <section className="wrapper">
          <Testimonial testimonialID={testimonialID} />
        </section>
      </div>

      <div style={{ position: "relative" }}>
        <div className={sky}>
          <div
            className="wrapper"
            style={{ position: "relative", zIndex: "1500", height: "100%" }}
          >
            <StaticImage
              className={mountainLeft}
              objectFit="contain"
              placeholder="none"
              quality="100"
              src="../../../images/mountain_left.svg"
              alt=""
            />
            <StaticImage
              className={mountainRight}
              objectFit="contain"
              placeholder="none"
              quality="100"
              src="../../../images/mountain_right.svg"
              alt=""
            />
          </div>

          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
        </div>
        <RocketReferralsCta
          h2="We can make NPS work for you."
          buttonText="Check out Rocket Referrals"
          link="https://www.rocketreferrals.com/features"
        />
      </div>
    </>
  );
}

IndiumAgency.defaultProps = {
  email: ``,
  name: ``,
  about: ``,
  website: ``,
  type: ``,
  nps: 0,
};

IndiumAgency.propTypes = {
  email: PropTypes.string,
  name: PropTypes.string.isRequired,
  about: PropTypes.string,
  website: PropTypes.string,
  type: PropTypes.string,
  nps: PropTypes.number.isRequired,
};
