// extracted by mini-css-extract-plugin
export var agencyInfoContainer = "AgencyStyleLarge-module--agency-info-container--2RfaD";
export var agencyInfoColumnOne = "AgencyStyleLarge-module--agency-info-column-one--2JbKd";
export var agencyInfoColumnTwo = "AgencyStyleLarge-module--agency-info-column-two--1_7C0";
export var agentProfileContainer = "AgencyStyleLarge-module--agent-profile-container--12gcp";
export var logoImage = "AgencyStyleLarge-module--logoImage--3mxsx";
export var logoTitle = "AgencyStyleLarge-module--logoTitle--2rcnS";
export var agencyHeadingTitle = "AgencyStyleLarge-module--agency-heading-title--15aKi";
export var agentProfileInfo = "AgencyStyleLarge-module--agent-profile-info--2Yu38";
export var agencyInfoFirstRow = "AgencyStyleLarge-module--agency-info-first-row--1Q7OF";
export var agentProfilePicture = "AgencyStyleLarge-module--agent-profile-picture--1NwnE";
export var agencyInfoSecondRow = "AgencyStyleLarge-module--agency-info-second-row--3X8z7";
export var agencyInfoThirdRow = "AgencyStyleLarge-module--agency-info-third-row--2cJ2t";
export var cardContainer = "AgencyStyleLarge-module--card-container--2fSEb";
export var cardShadow = "AgencyStyleLarge-module--card-shadow--3CP9L";
export var cardContentContainer = "AgencyStyleLarge-module--card-content-container--2DBem";