// extracted by mini-css-extract-plugin
export var agencyInfoContainer = "IndiumAgency-module--agency-info-container--1v9RZ";
export var agencyInfoColumnOne = "IndiumAgency-module--agency-info-column-one--XK3L_";
export var agencyInfoColumnTwo = "IndiumAgency-module--agency-info-column-two--2D959";
export var agentProfileContainer = "IndiumAgency-module--agent-profile-container--2Z4SJ";
export var logoImage = "IndiumAgency-module--logoImage--3U2Oy";
export var logoTitle = "IndiumAgency-module--logoTitle--3d7BG";
export var agencyHeadingTitle = "IndiumAgency-module--agency-heading-title--2ySD5";
export var agentProfileInfo = "IndiumAgency-module--agent-profile-info--3upmU";
export var agencyInfoFirstRow = "IndiumAgency-module--agency-info-first-row--3YWa6";
export var agentProfilePicture = "IndiumAgency-module--agent-profile-picture--96Lns";
export var agencyInfoSecondRow = "IndiumAgency-module--agency-info-second-row--38hFj";
export var agencyInfoThirdRow = "IndiumAgency-module--agency-info-third-row--3Vdve";
export var cardContainer = "IndiumAgency-module--card-container--2ebMw";
export var cardShadow = "IndiumAgency-module--card-shadow--3xOp1";
export var cardContentContainer = "IndiumAgency-module--card-content-container--KUVSo";
export var indiumBannerImage = "IndiumAgency-module--indium-banner-image--Zv1H_";
export var indiumImageContainer = "IndiumAgency-module--indium-image-container--1d-ga";
export var indiumLogo = "IndiumAgency-module--indiumLogo--1cfJS";