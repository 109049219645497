import * as React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { StaticImage } from "gatsby-plugin-image";
import {
  testimonialSection,
  testimonialContainer,
  star,
} from "./Testimonial.module.scss";

export default function Testimonial({ testimonialID }) {
  return (
    <div className={testimonialSection}>
      <StaticImage
        src="../../images/quote.svg"
        height={50}
        alt=""
        placeholder="none"
        style={{ marginBottom: "1.5rem" }}
      />
      <h2>What people are saying</h2>
      <div id="RRTestimonials" className={testimonialContainer}></div>

      {/* Helmet will place its children into the head of the page. This places the Testimonial widget with the passed in ID into the <head> */}
      <HelmetProvider>
        <Helmet>
          <script
            async
            src={`https://cf.rocketreferrals.com/jsloader/v2?c_id=${testimonialID}&limit=1&random=true`}
          ></script>
          <script>{`window.RRLoader = window.RRLoader || []; window.rrcall = window.rrcall || function () { RRLoader.push(arguments); };
rrcall( "set_testimonial_template",

\`{{#testimonials}}<p>{{comment}}</p> <i class="fas fa-star ${star}"></i> <i class="fas fa-star ${star}"></i> <i class="fas fa-star ${star}"></i> <i class="fas fa-star ${star}"></i> <i class="fas fa-star ${star}"></i> <h5>{{signature_from}}</h5> <p>{{signature_customer_since}}</p>{{/testimonials}}\`);

rrcall("load_testimonials", "RRTestimonials");`}</script>
        </Helmet>
      </HelmetProvider>
    </div>
  );
}
