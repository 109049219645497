// extracted by mini-css-extract-plugin
export var agencyInfoContainer = "AgencyStyleSmall-module--agency-info-container--3p0Fl";
export var agencyInfoColumnOne = "AgencyStyleSmall-module--agency-info-column-one--2pEqB";
export var agencyInfoColumnTwo = "AgencyStyleSmall-module--agency-info-column-two--2Zzjv";
export var agentProfileContainer = "AgencyStyleSmall-module--agent-profile-container--2ZjmE";
export var agentProfilePicture = "AgencyStyleSmall-module--agent-profile-picture--3bTwD";
export var agentProfileInfo = "AgencyStyleSmall-module--agent-profile-info--1hujQ";
export var agencyInfoFirstRow = "AgencyStyleSmall-module--agency-info-first-row--3RZ1u";
export var agencyHeadingTitle = "AgencyStyleSmall-module--agency-heading-title--3LqJ5";
export var agencyInfoSecondRow = "AgencyStyleSmall-module--agency-info-second-row--3AIWV";
export var agencyInfoThirdRow = "AgencyStyleSmall-module--agency-info-third-row--1RcXX";
export var cardContainer = "AgencyStyleSmall-module--card-container--3Rec1";
export var cardShadow = "AgencyStyleSmall-module--card-shadow--2Wj1-";
export var cardContentContainer = "AgencyStyleSmall-module--card-content-container--1-oO2";