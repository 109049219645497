import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { footer, poweredByContainer } from "./AgencyLayout.module.scss";
import Header from "../../components/Header/Header";

import "../../styles/global.scss";
import { sky, cloud } from "../../styles/design.module.scss";

/**
 * Layout is essentially the template for the entire site containing the header, <main>{children}</main>, footer.
 * We can use this Layout to wrap each of our pages giving us the no page reload while retaining our same layout.
 *
 * Wrapped in react fragments/ghost fragments so that we're not wrapping our entire site in a div or section.
 */

const AgencyLayout = ({ homepageLink, children }) => {
  return (
    <>
      <div
        style={{
          position: "relative",
          marginTop: "-4.5rem",
          paddingTop: "4.5rem",
        }}
      >
        <div className={sky}>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
        </div>
        <div className="wrapper header">
          <Header homepageLink={homepageLink} />
        </div>
      </div>
      <main>{children}</main>
      <div className={poweredByContainer}>
        Powered by:{" "}
        <a href="https://www.rocketreferrals.com">
          <StaticImage
            style={{ marginLeft: ".5rem" }}
            objectFit="contain"
            placeholder="none"
            width={180}
            src="../../images/rocket_referrals_logo.svg"
            alt="Rocket Referrals logo"
          />
        </a>
      </div>
      <footer className={footer}>
        © {new Date().getFullYear()} Rocket Referrals
        <br />
        *Net Promoter, Net Promoter System, Net Promoter Score, NPS and the
        NPS-related emoticons are registered trademarks of Bain & Company, Inc.,
        Fred Reichheld and Satmetrix Systems, Inc.
      </footer>
    </>
  );
};

export default AgencyLayout;
